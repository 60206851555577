<template>
  <div class="seckillInfoList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>秒杀列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>活动名称:</span>
      <el-input v-model="list.seckillName" placeholder="请输入活动名称"></el-input>
      <span>活动状态:</span>
      <el-select v-model="list.status" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span>是否显示:</span>

      <el-select clearable v-model="list.isShow" placeholder="请选择">
        <el-option
          v-for="item in isShowOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <br/>
      <br/>
      <span>万旅网显示:</span>

      <el-select clearable v-model="list.wlShow" placeholder="请选择">
            <el-option
              v-for="item in optionsShow"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
      <el-button type="primary" @click="getseckillInfoList()">查找</el-button>
      <el-button
        type="primary"
        @click="$router.push(`/addSeckill/0/0/0`)"
        v-if="$store.state.powerList.indexOf('seckill:info:list:add') !== -1"
        >添加</el-button
      >
    </div>
    <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column
        align="center"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column align="center" prop="detp" label="排序">
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.dept"
            ref="saveTagInput"
            size="small"
            @input="onInput"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="onChangeSort(row)"
            >{{ row.dept }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="seckillId"
        label="活动ID"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="seckillName"
        label="活动名称"
        width="300"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="seckillTime"
        label="活动时间"
        width="300"
      ></el-table-column>
      <el-table-column align="center" label="活动图片">
        <template slot-scope="{ row }">
          <el-image
            id="img"
            :src="row.imgUrl"
            :preview-src-list="[row.imgUrl]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="strIsBack"
        label="是否退订"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="strChange"
        label="是否改期"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="label"
        label="标签"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="stock"
        label="活动数量"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="strStatus"
        label="活动状态"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="cardType"
        label="限定身份证"
        width="width"
      ></el-table-column>
      <el-table-column align="center" prop="isShow" label="是否显示"> </el-table-column>
      <el-table-column align="center" prop="wlShow" label="万旅网显示"> </el-table-column>
      <el-table-column label="操作" width="350">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            style="margin: 5px"
            v-if="
              (row.status == 2 || row.status == 1 || row.status == 3) &&
              $store.state.powerList.indexOf('seckill:info:list:update') !== -1
            "
            @click="onInvalidRow(row.seckillId)"
            >使失效</el-button
          >

          <el-button
            size="mini"
            type="primary"
            v-if="$store.state.powerList.indexOf('seckill:info:list:edit') !== -1"
            @click="$router.push(`/addSeckill/${row.seckillId}/0/0`)"
            >编辑</el-button
          >

          <el-button
            size="mini"
            type="primary"
            v-if="$store.state.powerList.indexOf('seckill:info:list:delete') !== -1"
            @click="onDelRow(row.seckillId)"
            >删除</el-button
          >

          <el-button
          
            size="mini"
            type="primary"
            v-if="$store.state.powerList.indexOf('seckill:upgrade:list:select') !== -1 && row.type !=5"
            @click="onIsUpgrade(row.seckillId)"
            >升级设置</el-button
          >
          <el-button size="mini" type="primary" @click="onShowAddress(row.seckillId)"
            >查看页面地址</el-button
          >
          <el-button size="mini" v-if="row.type !=5" type="primary" @click="onSetPrice(row.seckillId)"
            >设置价格</el-button
          >
          <el-button size="mini" type="primary" @click="createCode(row.seckillId)"
            >生成小程序码</el-button
          >
          <el-button size="mini" style="margin:5px" v-if="$store.state.powerList.indexOf('seckill:info:list:log') !== -1" type="primary" @click="$router.push('/seckillLog?id='+row.seckillId)"
            >查看日志</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="btn">
      <el-button
        type="primary"
        @click="onChangeAllStatusShow(1)"
        v-if="$store.state.powerList.indexOf('seckill:info:list:wlShow') !== -1"
        >万旅网批量显示</el-button
      >
      <el-button
        type="primary"
        @click="onChangeAllStatusShow(0)"
        v-if="$store.state.powerList.indexOf('seckill:info:list:wlShow') !== -1"
        >万旅网批量隐藏</el-button
      >
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog title="查看页面详情地址" :visible.sync="showSrc" width="600px">
      <div id="address">
        前台详情地址:<el-input
          style="width: 350px"
          v-model="address"
          readonly
          id="wrapper"
        ></el-input>
        <el-button type="primary" icon="el-icon-document-copy" @click="urla"
          >一键复制</el-button
        >
      </div>
      <div slot="footer">
        <el-button @click="showSrc = false">取 消</el-button>
        <el-button type="primary" @click="showSrc = false">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="codeVisible" width="30%">
      <div class="inner_top">
        <el-input
          style="width: 300px"
          v-model.trim="QRcodeSize"
          placeholder="请输入生成的二维码大小"
        ></el-input>

        <el-button type="primary" @click="generateQRcode">生成</el-button>
      </div>
      <div style="color: red">推荐宽度：280px-1280px</div>
      <el-image v-show="imgUrl" fit="fill" :src="imgUrl"></el-image>
      <span slot="footer" class="dialog-footer">
        <span>右键点击图片，点击另存为保存图片</span>
        <el-button @click="codeVisible = false">取 消</el-button>
        <el-button type="primary" @click="codeVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  seckillInfoList,
  seckillInfoInvalid,
  delSeckillInfo,
  selectIsUpgrade,
  wanlvSeckillShow
} from "../../../api/seckillInfo";
import { changeSort } from "../../../api/seckillInfo";
import { createEr7 } from "../../../api/erCode";
export default {
  name: "seckillInfoList",
  data() {
    return {
      seckillId: "",
      QRcodeSize: "",
      codeVisible: false,
      imgUrl: "",
      isShowOptions: [
        {
          value: -2,
          label: "全部",
        },
        {
          value: 1,
          label: "显示",
        },
        {
          value: 0,
          label: "隐藏",
        },
      ],
      options: [
        { value: 0, label: "全部" },
        { value: 1, label: "进行中" },
        { value: 2, label: "未开始" },
        { value: 3, label: "已结束" },
        { value: 4, label: "已失效" },
      ],
      optionsShow: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 1,
          label: "显示",
        },
        {
          value: 0,
          label: "隐藏",
        },
      ],
      address: "",
      showSrc: false,
      tableData: [],
      pagination: {},
      list: {
        currentPage: 1,
        pageSize: 5,
        seckillName: "",
        status: null,
        isShow: -2,
        wlShow:-1
      },
      ids: "",
    };
  },
  created() {
    this.getseckillInfoList();
  },
  methods: {
    handleSelectionChange(val) {
      this.ids = val.map((item) => item.seckillId).join(",");
    
    },
    onChangeAllStatusShow(status){
      if(!this.ids){
        return this.$message({
          type: "warning",
          message: "请选择要修改的内容!",
        });
      }
      // 是否修改
      this.$confirm("是否要批量修改选中秒杀的状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          console.log(this.ids)
          const { data } = await wanlvSeckillShow({
            seckillId: this.ids,
            wlShow:status,
          });
          if (data.code === 0) {
            this.getseckillInfoList();
            this.$message({
              type: "success",
              message: data.msg,
            });
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          
        });

    },
    createCode(id) {
      this.codeVisible = true;
      this.seckillId = id;
      this.imgUrl = "";
    },
    async generateQRcode() {
      this.imgUrl = "";
      if (!this.QRcodeSize) {
        this.$message({ message: "请输入宽度", type: "warning" });
      } else {
        const { data } = await createEr7({
          seckillId: this.seckillId,
          widthSize: this.QRcodeSize,
        });
        this.imgUrl = data.msg;
        console.log(this.imgUrl);
      }
    },
    onSetPrice(id) {
      this.$router.push(`/seckillPrice/${id}`);
    },
    onShowAddress(id) {
      this.address = "seckill/pages/seckillDetail/seckillDetail?seckillId=" + id;
      this.showSrc = true;
    },
    urla() {
      var inputElement = document.getElementById("wrapper");
      //选中input框的内容
      inputElement.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      this.$message({ message: "复制成功", type: "success" }); //此处为结合使用element-ui的Message 消息提示组件
    },
    async getseckillInfoList() {
      const { data } = await seckillInfoList(this.list);
      data.list.map((item) => {
        item.inputVisible = false;
      });
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      var reg2 = /^-[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value) && !reg2.test(value)) {
        return this.$message({
          type: "warning",
          message: "请输入整数!",
        });
      }
    },
    onChangeSort(row) {
      row.inputVisible = true;
    },
    async handleInputConfirm(row) {
      const { data } = await changeSort({
        seckillId: row.seckillId,
        dept: row.dept,
      });
      row.inputVisible = false;
      this.getseckillInfoList();
    },
    async onIsUpgrade(seckillId) {
      const { data } = await selectIsUpgrade({
        seckillId,
      });
      if (data.code == 0) {
        this.$message.success(data.msg);
        this.$router.push(`/secKillUpgradeList/${seckillId}`);
      } else {
        this.$message.error(data.msg);
      }
    },
    onDelRow(seckillId) {
      this.$confirm("是否要删除这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delSeckillInfo({
            seckillId,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getseckillInfoList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onInvalidRow(seckillId) {
      this.$confirm("是否要让这条秒杀失效?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await seckillInfoInvalid({
            seckillId,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getseckillInfoList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getseckillInfoList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getseckillInfoList();
    },
  },
};
</script>
<style lang="less" scoped>
.btn {
    float: left;
    margin-top: 50px;
  }
.seckillInfoList {
  //解决未知的窗口抖动👇
  padding-right: 0px !important;
  overflow: hidden;
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    span {
      margin: 0 20px 0 40px;
    }
    .el-input {
      width: 400px;
    }
    .el-button {
      margin-left: 20px;
    }
  }
  .el-table {
    margin-top: 50px;
    #img {
      width: 108px;
      height: 61px;
    }
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>
