import request from '../utils/request'
import baseUrl from './baseUrl'
  //生成二维码
    export const createEr = (data) => request({
        url: baseUrl + '/travel/selectErCode',
        method: 'POST',
        data
      })
	//生成二维码
    export const createEr2 = (data) => request({
        url: baseUrl + '/room/selectErCode',
        method: 'POST',
        data
      })
	//生成二维码
    export const createEr3 = (data) => request({
        url: baseUrl + '/ticket/selectErCode',
        method: 'POST',
        data
      }) 
	//生成二维码
    export const createEr4 = (data) => request({
        url: baseUrl + '/prod/shareErCode',
        method: 'POST',
        data
      })
	//生成二维码
    export const createEr5 = (data) => request({
        url: baseUrl + '/card/shareCard',
        method: 'POST',
        data
      })
      //生成二维码
    export const createEr6 = (data) => request({
      url: baseUrl + '/groupBuy/shareGroupBuy',
      method: 'POST',
      data
    })
      //生成二维码
      export const createEr7 = (data) => request({
        url: baseUrl + '/seckillInfo/shareSeckill',
        method: 'POST',
        data
      })
      //生成二维码
      export const createEr8 = (data) => request({
        url: baseUrl + '/presaleInfo/sharepresale',
        method: 'POST',
        data
      })